/* hover bg class start */
@each $color, $shades in $colors {
  @each $shade, $value in $shades {
    .bg-#{$color}-#{$shade} {
      background-color: var(--#{$color}-#{$shade}) !important;
    }
  }
}

// hover background
@each $color, $shades in $colors {
  @each $shade, $value in $shades {
    .hover-bg-#{$color}-#{$shade} {
      &:hover {
          background-color: var(--#{$color}-#{$shade}) !important;
      }
    }
  }
}

.hover-bg-white {
  &:hover {
    background-color: hsl(var(--white)) !important;
  }
}

.bg {
  &-green {
    background-color: var(--green);
  }
  &-white-01 {
    background-color: rgba(255, 255, 255, 0.04);
  }
  &-white-1 {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
/* hover bg class end */