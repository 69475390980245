/* ============================= CSS table of contents and Scss Indexing ================================
* Name: Transport & Logistics HTML Template
* Version: 1.0.0
* Author: wowtheme7
* Author URI: https://themeforest.net/user/wowtheme7 

* abstracts
    * Functions
    * Variable
    * Mixins

* utilities
    * typography
    * bg img
    * bg
    * border
    * colors
    * colors hover
    * container
    * cursor
    * outline
    * classes
    * font size
    * font
    * grid
    * height
    * gap
    * group
    * inset
    * line clamp
    * margin
    * max height
    * max width
    * overlay
    * padding
    * placeholder
    * rounded
    * rotate
    * scale
    * translate
    * scroll hide
    * scrollbar
    * shadow
    * transition
    * duration
    * underline
    * width
    * z index
    * text stroke
    * letter spacing

*  components
    * accordion
    * button
    * form
    * tab
    * table
    * swiper slider

* layout  
    * preloader
    * header
    * mobile nav menu
    * scroll top
    * clip path
    * logo mask bg
    * clamp
    * line
    * blur
    * custom cursor
    * gradient
    * half bg

* partials
    * animation
    * banner
    * features
    * about
    * counter
    * key features
    * how it work
    * play btn
    * project
    * location
    * quote
    * testimonials brand
    * map
    * footer
    * header middle
    * banner two
    * about two
    * pricing
    * project two
    * floating progress label
    * banner three
    * service three
    * about three
    * track order
    * counter three
    * blog three
============================ CSS table of contents and Scss Indexing ================================ */