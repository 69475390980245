

/* Mobile Menu Sidebar Start */
.mobile-menu {
    &.active {
        transform: translateX(0%);
    }
}

.nav-menu {
    &--mobile {
        .nav-menu {
            &__item {
                > a {
                    &::before {
                        transform: translateY(-50%) rotate(0deg);
                    }
                }
            }
            &__link {
                border-bottom: 1px solid var(--neutral-200) !important;
                position: relative;
                padding: 12px 0;
                padding-inline-end: 16px;
              
            } 
        }
        .nav-submenu {
            position: static !important;
            visibility: visible;
            opacity: 1;
            box-shadow: none;
            width: 100%;
            margin-block-start: 0;
            padding: 0;
            margin-inline-start: 16px;
            display: none;
            transition: 0s;
            &__link {
                width: 100%;
                padding: 10px 0;
                border-bottom: 1px solid var(--neutral-200) !important;
            }
            &__item {
                &:hover {
                    background-color: transparent;
                    color: var(--main-600);
                }
                &::before {
                    display: none;
                }
            }
        }
    }
}
/* Mobile Menu Sidebar Start */