/* =============================== Container Css Start ===================================== */
.tw-container {
    &-1380-px {
        max-width: 1380px;
    }
    &-1540-px {
        max-width: 1540px;
    }
    &-1554-px {
        max-width: 1554px;
    }
    &-1742-px {
        max-width: 1742px;
    }
    &-1760-px {
        max-width: 1760px;
    }
}
/* =============================== Container Css End ===================================== */