/* =================== Blog Css Start =========================== */
.swiper-slide.swiper-slide-next {
    .blog-three-item {
        background-color: var(--main-600) !important;
        h5, a, .text-main-600, .text-neutral-600 {
            color: #fff;
        }
    }
}
/* =================== Blog Css end =========================== */
