/* transition Css Class start */
.tw-transition {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    &-all {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }
}

.tw-delay-200 {
    transition-delay: 200ms;
}
/* transition Css Class End */
