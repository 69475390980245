/* Projects Css Start */
.half-bg-w-50 {
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 50%;
        background-color: var(--neutral-50);
        inset-inline-start: 0;
        inset-block-end: 0;
        z-index: -1;
    }
}

.view-w-100 {
    width: 100vw;
}

.hover-overlay {
    position: relative;
    z-index: 1;
    &:hover {
        &::before {
            height: 100%;
        }
    }   
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 0;
        inset-inline-start: 0;
        inset-block-end: 0;
        background: linear-gradient(180deg, rgba(6, 45, 53, 0) 0%, rgba(6, 45, 53, 0) 64.33%, #030404 100%);
        transition: .2s linear;
    }
    &.overlay-main-600 {
        &::before {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 19.36%, rgba(223, 17, 25, 0.76) 71.26%, var(--main-600) 100%);
        }
    }
}
/* Projects Css End */