.half-bg-h-32 {
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 32%;
        background-color: var(--neutral-50);
        inset-inline-start: 0;
        inset-block-end: 0;
        z-index: -1;
    }
}