/* Max width Css Class Start */
.max-w {
    &-100-px {
        max-width: 100px;
    }
    &-110-px {
        max-width: 110px;
    }
    &-82-px {
        max-width: 82px;
    }
    &-130-px {
        max-width: 130px;
    }
    &-148-px {
        max-width: 148px;
    }
    &-158-px {
        max-width: 158px;
    }
    &-190-px {
        max-width: 190px;
    }
    &-175-px {
        max-width: 175px;
    }
    &-200-px {
        max-width: 200px;
    }
    &-210-px {
        max-width: 210px;
    }
    &-260-px {
        max-width: 260px;
    }
    &-240-px {
        max-width: 240px;
    }
    &-280-px {
        max-width: 280px;
    }
    &-286-px {
        max-width: 286px;
    }
    &-310-px {
        max-width: 310px;
    }
    &-318-px {
        max-width: 318px;
    }
    &-390-px {
        max-width: 390px;
    }
    &-400-px {
        max-width: 400px;
    }
    &-430-px {
        max-width: 430px;
    }
    &-444-px {
        max-width: 444px;
    }
    &-468-px {
        @media (min-width: 992px) {
            max-width: 468px;
        }
    }
    &-520-px {
        max-width: 520px;
    }
    &-548-px {
        max-width: 548px;
    }
    &-632-px {
        max-width: 632px;
    }
    &-610-px {
        max-width: 610px;
    }
    &-620-px {
        max-width: 620px;
    }
    &-660-px {
        max-width: 660px;
    }
    &-856-px {
        max-width: 856px;
    }
    &-840-px {
        max-width: 840px;
    }
    &-840-px {
        @media (min-width: 1200px) {
            max-width: 840px;
        }
    }
    &-1024-px {
        max-width: 1024px;
    }
    &-64-percent {
        max-width: 64%;
    }
}
/* Max width Css Class End */