// border
@each $color, $shades in $colors {
    @each $shade, $value in $shades {
      .border-#{$color}-#{$shade} {
        border-color: var(--#{$color}-#{$shade}) !important;
      }
    }
}

// hover border
@each $color, $shades in $colors {
    @each $shade, $value in $shades {
      .hover-border-#{$color}-#{$shade} {
        &:hover {
            border-color: var(--#{$color}-#{$shade}) !important;
        }
      }
    }
  }

// focus border
@each $color, $shades in $colors {
    @each $shade, $value in $shades {
      .focus-border-#{$color}-#{$shade} {
        &:focus {
            border-color: var(--#{$color}-#{$shade}) !important;
        }
      }
    }
  }

  .tw-border-dashed {
    border-style: dashed !important;
  }

  .tw-border {
    &-8 {
      border-width: 8px !important; 
    }
  }