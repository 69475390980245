
/* Bg Image Css Start */
.bg-img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    width: 100%;
    height: 100%;
}
/* Bg Image Css End */
