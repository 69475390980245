/* Map Css Start */
.location {
    @media (min-width: 992px) {
        position: absolute;
        inset-inline-start: 50%;
        inset-block-start: 50%;
        transform: translateY(-50%);
    }
}

.location-item {
    position: absolute !important;
    inset-inline-start: 25%;
    inset-block-start: 22%;
    &:nth-child(2) {
        inset-block-start: 56%;
        inset-inline-start: 56%;
    }
    &:nth-child(3) {
        inset-inline-start: auto;
        inset-inline-end: 10%;
        inset-block-start: 26%;
    }
    &:nth-child(4) {
        inset-block-start: 15%;
        inset-inline-start: 62%;
    }
    &:nth-child(5) {
        inset-block-start: auto;
        inset-block-end: 18%;
        inset-inline-start: 29%;
    }
    &:nth-child(6) {
        inset-inline-start: auto;
        inset-block-start: auto;
        inset-inline-end: 16%;
        inset-block-end: 29%;
    }
}
/* Map Css End */