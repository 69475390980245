/* Height Css Class Start */
@each $property, $value in $spaces {
    .tw-h-#{$property} {
      height: $value;
    }
}

.tw-h {
  &-25-px {
    height: 25px;
  }
  &-70-px {
    height: 70px;
  }
  &-75-px {
    height: 75px;
  }
  &-85-px {
    height: 85px;
  }
  &-90-px {
    height: 90px;
  }
  &-95-px {
    height: 95px;
  }
  &-114-px {
    height: 114px;
  }
  &-108-px {
    height: 108px;
  }
  &-140-px {
    height: 140px;
  }
  &-144-px {
    height: 144px;
  }
  &-150-px {
    height: 150px;
  }
  &-280-px {
    height: 280px;
  }
  
  &-400-px {
    height: 400px;
  }
}

.tw-h {
  &-screen {
    height: 100vh;
  }
  &-60-percent {
    height: 62%;
  }
}

.tw-h-100--44-px {
  height: calc(100% - 45px) !important;
}


.tw-min-h {
  &-300-px {
    min-height: 300px;
  }
  &-500-px {
    min-height: 500px;
  }
}
/* Height Css Class End */
