/* ======================== Animation Css Start ==================================== */
.curve-animation {
    animation: 8s curveAnimation linear infinite;
}

@keyframes curveAnimation {
    0% {
        transform: rotateZ(24deg);
    }
    50% {
        transform: rotateZ(-24deg);
    }
    100% {
        transform: rotateZ(24deg);
    }
}

.scalable-animation {
  &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      inset-inline-start: 50%;
      inset-block-start: 50%;
      transform: translate(-50%, -50%);
      background: inherit;
      border-radius: inherit;
      animation: scalable-animation 1.5s 0s infinite;
      z-index: -1;
  }
}

@keyframes scalable-animation {
  0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.8;
  }
  100% {
      transform: translate(-50%, -50%) scale(3);
      opacity: 0;
  }
}

.animate__wobble__two {
  animation: wobble 2.4s ease-in-out infinite;
}



/* Raw animations start here */
.animation-item:hover .animate__bounce {
    animation: bounce 1.5s;
  }
  
  .animation-item:hover .animate__wobble {
    animation: wobble 1.5s ease-in-out;
  }
  
  .animation-item:hover .animate__heartBeat {
    animation: heartbeat 1.5s ease-in-out;
  }
  
  .animation-item:hover .animate__flipInY {
    animation: flipInY 1.5s ease-in-out;
  }
  
  .animation-item:hover .animate__swing {
    animation: swing 1.5s ease-in-out;
  }
  
  @keyframes bounce {
    0%, 20%, 53%, 100% {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    40%, 43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
      transform: translate3d(0, -30px, 0) scaleY(1.1);
    }
    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
      transform: translate3d(0, -15px, 0) scaleY(1.05);
    }
    80% {
      -webkit-transform: translateZ(0) scaleY(0.95);
      transform: translateZ(0) scaleY(0.95);
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    90% {
      -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
      transform: translate3d(0, -4px, 0) scaleY(1.02);
    }
  }
  @keyframes wobble {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
    15% {
      -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
      transform: translate3d(-25%, 0, 0) rotate(-5deg);
    }
    30% {
      -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
      transform: translate3d(20%, 0, 0) rotate(3deg);
    }
    45% {
      -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
      transform: translate3d(-15%, 0, 0) rotate(-3deg);
    }
    60% {
      -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
      transform: translate3d(10%, 0, 0) rotate(2deg);
    }
    75% {
      -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
      transform: translate3d(-5%, 0, 0) rotate(-1deg);
    }
    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  @keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    14% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
    28% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    42% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
    70% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes flipInY {
    0% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
      -webkit-transform: perspective(400px) rotateY(90deg);
      transform: perspective(400px) rotateY(90deg);
    }
    40% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: perspective(400px) rotateY(-20deg);
      transform: perspective(400px) rotateY(-20deg);
    }
    60% {
      opacity: 1;
      -webkit-transform: perspective(400px) rotateY(10deg);
      transform: perspective(400px) rotateY(10deg);
    }
    80% {
      -webkit-transform: perspective(400px) rotateY(-5deg);
      transform: perspective(400px) rotateY(-5deg);
    }
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }
  @keyframes swing {
    20% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg);
    }
    40% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg);
    }
    60% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
    80% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  /* ======================== Animation Css End ==================================== */
