/* ============================== About Css Start =============================== */
.before-border {
    z-index: 1;
    &::before, &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &::before {
        border: 1px solid var(--main-two-600);
        inset-inline-end: 6px;
        inset-block-end: -6px;
    }
    &::after {
        background-color: hsl(var(--white));
        inset-inline-end: 0;
        inset-block-end: 0;
    }
}

.positioned-image {
    transform: translateX(-48px) translateY(-36px) !important;
}

.truck-head {
    top: 73%;
}

@media (max-width: 1199px) {
    .max-lg-overflow-hidden {
        overflow: hidden;
    }
}
/* ============================== About Css End =============================== */