
/* Colors class start */
@each $color, $shades in $colors {
    @each $shade, $value in $shades {
      .text-#{$color}-#{$shade} {
        color: var(--#{$color}-#{$shade});
      }
    }
  }
  
.text {
    &--white {
      color: hsl(var(--white));
    }
    &-heading {
      color: hsl(var(--heading-color)) !important;
    }
    &-body {
      color: hsl(var(--body-color));
    }
    &-star {
      color: var(--star-color);
    }
}
/* Colors class End */