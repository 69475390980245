
.bg-inherit-animation {
    &::before {
        position: absolute;
        content: "";
        width: inherit;
        height: inherit;
        background: inherit;
        border-radius: 50%;
        inset-inline-start: 0;
        top: 0;
        z-index: -1;
        -webkit-animation: animate 2.3s ease-out infinite;
        animation: animate 2.3s ease-out infinite;
    }
}

.bg-light-animation {
    &::after {
        position: absolute;
        content: "";
        width: inherit;
        height: inherit;
        background: inherit;
        border-radius: inherit;
        inset-inline-start: 0;
        top: 0;
        z-index: -1;
    }
    &::before {
        position: absolute;
        content: "";
        width: inherit;
        height: inherit;
        background: hsl(var(--white)/.2);
        border-radius: 50%;
        inset-inline-start: 0;
        top: 0;
        z-index: -1;
        -webkit-animation: animate 2.3s ease-out infinite;
        animation: animate 2.3s ease-out infinite;
    }
}

.circle-border {
  &::after {
    position: absolute;
    content: "";
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border: 1px dashed var(--main-two-600);
    border-radius: 50%;
    inset-inline-start: 50%;
    inset-block-start: 50%;
    transform: translate(-50%, -50%);
}
}
  
  @keyframes animate {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    30% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
    }
    60% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0.9;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(2);
      opacity: 0;
    }
  }