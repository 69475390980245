.tw-duration {
    &-75 {
        transition-duration: 75ms;
    } 
    &-100 {
        transition-duration: 100ms;
    } 
    &-150 {
        transition-duration: 150ms;
    } 
    &-200 {
        transition-duration: 200ms;
    } 
    &-300 {
        transition-duration: 300ms;
    } 
    &-500 {
        transition-duration: 500ms;
    }
}
