/* Border Radius Css Start */
.tw-rounded {
   border-radius: 0.25rem; /* 4px */
   &-none {
      border-radius: 0px;
   }
   &-sm {
      border-radius: 0.125rem; /* 2px */
   }
   &-md {
      border-radius: 0.375rem; /* 6px */
   }
   &-lg {
      border-radius: 0.5rem !important; /* 8px */
   }
   &-xl {
      border-radius: 0.75rem; /* 12px */
   }
   &-2xl {
      border-radius: 1rem; /* 16px */
   }
   &-3xl {
      border-radius: 1.5rem; /* 24px */
   }
   &-32-px {
      border-radius: 2rem; /* 32px */
   }

   &-tl-0 {
      border-top-left-radius: 0 !important;
   }
   &-tr-0 {
      border-top-right-radius: 0 !important;
   }
   &-br-0 {
      border-bottom-right-radius: 0 !important;
   }
   &-bl-0 {
      border-bottom-left-radius: 0 !important;
   }
   &-br-0 {
      border-bottom-right-radius: 0 !important;
   }
   &-bl-2xl {
      border-bottom-left-radius: 1rem;
   }
   &-br-2xl {
      border-bottom-right-radius: 1rem;
   }
   
}
/* Border Radius Css End */