
/* Overlay Start */
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    content: "";
    inset-inline-start: 0;
    inset-block-start: 0;
    background-color: hsl(var(--black) / .6);
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
    &.show-overlay {
        visibility: visible !important;
        opacity: 1 !important;
    }
}

.side-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    content: "";
    inset-inline-start: 0;
    inset-block-start: 0;
    background-color: hsl(var(--black) / .6);
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
    &.show {
        visibility: visible !important;
        opacity: 1 !important;
    }
}


.overlay-left-to-right {
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, #032532, #092a37c4 50%);
        inset-block-start: 0;
        inset-inline-start: 0;
        border-radius: inherit;
        z-index: -1;
    }
}
/* Overlay End */