/* transport Way Css Start */
.half-bg-white {
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 50%;
        background-color: hsl(var(--white));
        inset-inline-start: 0;
        inset-block-end: 0;
        z-index: -1;
    }
    &.bg-neutral {
        &::before {
            background-color: var(--neutral-25);
        }
    }
}


.how-it-work-item-wrapper {
    @media (min-width: 992px) {
        padding-block-end: 74px;
        border-bottom: 1px dashed var(--neutral-800);
    }
}

@media (min-width: 992px) { 
    .transport-card {
        &::after, &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 74px;
            border-inline-end: 1px dashed var(--neutral-400);
            inset-block-start: 100%;
            inset-inline-start: 50%;
            transform: var(--translate-x-50);
        }
        &::after {
            width: 20px;
            height: 20px;
            background-color: var(--main-600);
            border-radius: 50%;
            border: 0;
            inset-block-start: calc(100% + 64px);
        }
        &.style-two {
            &:hover {
                &::after {
                    background-color: var(--main-600);
                    color: #fff;
                }
            }
            &::after {
                content: counter(number);
                counter-increment: number;
                width: 40px;
                height: 40px;
                background-color: hsl(var(--white));
                color: var(--main-two-600);
                font-size: 14px;
                font-weight: 600;
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .3s linear;
                inset-block-start: calc(100% + 54px);
            }
        }
    }
    
    .how-it-work-item {
        position: relative;
        &::after {
            position: absolute;
            content: counter(number);
            counter-increment: number;
            inset-inline-start: 50%;
            transform: var(--translate-x-50);
            inset-block-start: calc(100% + 90px);
            z-index: 1;
            font-size: 1rem;
            font-weight: 600;
            color: var(--main-two-600);
        }
    }
}


.rocket {
    animation: rocketAnimation 10s  linear infinite;
}
@keyframes rocketAnimation
{
	0%   {
        left: -24px;
        opacity: 0;
        transform: scale(.2);
    }
    5% {
        opacity: .5;
    }
    10% {
        opacity: 1;
        transform: scale(1);
    }
	90%   {
        opacity: 1;
    }
	91%   {
        opacity: .5;
        transform: scale(1);
    }
	100% {
        left: 100%;
        opacity: 0;
        transform: scale(.2);
    }
} 
/* transport Way Css End */