/* ================================= Form Css Start =========================== */
/* input File Start */
.common-file[type="file"] {
    line-height: 50px;
    padding: 0;
    position: relative;
    &::file-selector-button {
        border: 1px solid hsl(var(--black) / .08);
        padding: 4px 6px;
        border-radius: .2em;
        background-color: hsl(var(--main)) !important;
        transition: .2s linear;
        line-height: 25px;
        position: relative;
        margin-inline-start: 15px;
        color: hsl(var(--body-color)) !important;
        &:hover {
            background-color: var(--main-600);
            border: 1px solid var(--main-600);
            color: hsl(var(--black));
        }
    }
}
/* input File End */

/* Autofill Css */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, 
textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-text-fill-color: hsl(var(--heading-color)) !important;
    caret-color: hsl(var(--heading-color));
}
/* Autofill Css End */

/* Number Arrow None */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type=number]{
    -moz-appearance: textfield;
}

/* Custom Checkbox & Radio Css Start */
.common-check {
    display: flex; 
    align-items: center;
    margin-block-end: 16px;
    &:last-child {
        margin-block-end: 0;
    }
    a {
        display: inline;
    }
    &.common-radio {
        .form-check-input {
            border-radius: 50%;
            &:checked { 
                background-color: transparent !important;
                &::after {
                    visibility: visible;
                    opacity: 1;
                    -webkit-transform: translate(-50%, -50%) scale(1);
                    transform: translate(-50%, -50%) scale(1);
                }
                &::before {
                    display: none;
                }
            }
        }
    }

    .form-check-input {
        transition: .2s linear;
        box-shadow: none;
        background-color: transparent;
        box-shadow: none !important;
        border: 0;
        position: relative;
        border-radius: 0px;
        width: 18px;
        height: 18px;
        border: 2px solid hsl(var(--black) / .4);   
        cursor: pointer;
        transition: .2s linear;
        margin-block-start: 0;
        &::before {
            position: absolute;
            content: "\E182";
            font-family: "Phosphor";
            font-weight: 900;
            color: hsl(var(--white)); 
            font-size: 12px;
            inset-block-start: 50%;
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);
            transition: .2s linear;
            visibility: hidden;
            opacity: 0;
        }
        &::after {
            position: absolute;
            content: "";
            inset-block-start: 50%;
            inset-inline-start: 50%;
            -webkit-transform: translate(-50%, -50%) scale(.2);
            transform: translate(-50%, -50%) scale(.2);
            width: 10px;
            height: 10px;
            background-color: var(--main-two-600);
            border-radius: 50%;
            transition: .2s linear;
            z-index: 1;
            visibility: hidden;
            opacity: 0;
        }
        &:checked {
            background-color: var(--main-two-600) !important;
            box-shadow: none;
            &[type=checkbox] {
                background-image:none;
            }
            &::before {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
/* Custom Checkbox & Radio Css End */


/* Switch Css Start */
.form-switch {
    padding-inline-start: 0;
    .form-check-input {
      box-shadow: none !important;  
      margin: 0;
      position: relative;
      background-color: var(--gray-100);
      border: 0;
      background-image: none !important;
      width: 30px;
      height: 18px;
      &:checked {
        background-color: var(--primary-600);
        &::before {
          inset-inline-start: calc(100% - 15px);
          transition: .2s linear;
        }
      }    
      &::before {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        inset-inline-start: 3px;
        transition: .2s linear;
      }
    }
}
/* Switch Css End */
/* ================================= Form Css End =========================== */