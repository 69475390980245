/* Text Decoration Start */
.hover {
    &-underline {
        &:hover {
            text-decoration: underline;
        }
    }
    &-underline-none {
        &:hover {
            text-decoration: none !important;
        }
    }
}
/* Text Decoration End */
