/* Counter three section css start  */
.shape-bg-main-600 {
    &::before {
        position: absolute;
        content: "";
        width: 130%;
        height: calc(100% - 134px);
        inset-block-start: 0;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        background-color: var(--main-two-600);
        z-index: -1;
        border-radius: 0 0 50% 50%;
    }
}
/* Counter three section css End  */