.logo-mask-bg {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 150px;
        background-color: var(--main-600); 
        mask-image: url('../images/shapes/logo-shape.png'); 
        mask-size: contain; 
        mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-image: url('../images/shapes/logo-shape.png'); 
        -webkit-mask-size: cover;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        z-index: -1;
    }
}
